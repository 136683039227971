



































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {NftCollection} from '@/model/resource/NftCollection'
import NetworkTag from '@/components/NetworkTag.vue'

@Component({
  components: {NetworkTag},
})
export default class NftCollectionCellRender extends MixinScreenSize {
  @Prop({type: NftCollection, required: true}) nftCollection!: NftCollection

  get formattedAddress() {
    if (!this.nftCollection.ownerAddress) return undefined

    return `${this.nftCollection.ownerAddress.slice(
      0,
      4
    )}...${this.nftCollection.ownerAddress.slice(-4)}`
  }

  get ghostMarketCollectionUrl() {
    return `${process.env.VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL}${this.nftCollection.slug}`
  }

  copyToClipboard() {
    this.$utils.copyToClipboard(this.nftCollection.ownerAddress ?? '')
    this.$toast.info('system.info.copiedToClipboard')
  }
}
