









































































































































































import {Component, Watch, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {ExpansibleCollection, IResource} from '@simpli/resource-collection'
import {DefaultSchema} from '@/schema/DefaultSchema'
import NftToggleButton from '@/components/misc/GenericToggleButton.vue'
import NftCollectionToggleButton from '@/components/misc/GenericToggleButton.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import TruncateCellRender from '@/components/render/TruncateCellRender.vue'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import {DEFAULT_NETWORK} from '@/libs/blockchain-services/constants/BSNeo3Constants'
import {NftCollection} from '@/model/resource/NftCollection'
import NftCollectionImageRender from '@/components/render/NftCollectionImageRender.vue'
import NftCollectionCellRender from '@/components/render/NftCollectionCellRender.vue'
import NftCollectionMonthVolumeRender from '@/components/render/NftCollectionMonthVolumeRender.vue'

@Component({
  components: {
    NftCollectionMonthVolumeRender,
    NftCollectionCellRender,
    NftCollectionImageRender,
    TruncateCellRender,
    PercentageProgressRender,
    GenericToggleButton,
    NftCollectionToggleButton,
    NftToggleButton,
    MoonLoader,
  },
})
export default class NftScrollableTable extends MixinScreenSize {
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: Array, required: true}) items!: NftCollection[]
  @Prop({type: String, default: '30-DAY VOLUME'}) defaultSortKey!: string

  headers = [
    'NFTs',
    '',
    '7-DAY AVG PRICE',
    '7D %',
    'FLOOR PRICE',
    'TOTAL SUPPLY',
    '30-DAY VOLUME',
  ]

  isLoading = true

  localItems: NftCollection[] = []
  currentSortKey = this.defaultSortKey
  currentSortDirection: 'asc' | 'desc' = 'desc'

  @Watch('items', {immediate: true, deep: true})
  onItemsChanged(newItems: NftCollection[]) {
    this.localItems = [...newItems]
  }

  sortValues(value: string) {
    const keyMap: Record<
      string,
      (item: NftCollection) => number | string | null
    > = {
      NFTs: item => item.name,
      '7-DAY AVG PRICE': item => item.averagePriceWeekFormatted,
      '7D %': item => item.weekPriceChange,
      'FLOOR PRICE': item => item.lowestPriceFormatted,
      'TOTAL SUPPLY': item => item.totalSupplyFormatted,
      '30-DAY VOLUME': item => item.monthVolumePriceFormatted,
    }

    const keyFn = keyMap[value]
    if (!keyFn) return

    const isSameKey = this.currentSortKey === value
    this.currentSortDirection = isSameKey
      ? this.currentSortDirection === 'asc'
        ? 'desc'
        : 'asc'
      : 'desc'

    this.currentSortKey = value

    this.localItems.sort((a, b) => {
      const valA = keyFn(a) ?? 0
      const valB = keyFn(b) ?? 0

      let result = 0

      if (
        value === 'NFTs' &&
        typeof valA === 'string' &&
        typeof valB === 'string'
      ) {
        result = valA.localeCompare(valB)
      } else {
        const numA =
          typeof valA === 'string'
            ? parseFloat(valA.replace(/[^\d.-]/g, ''))
            : Number(valA)
        const numB =
          typeof valB === 'string'
            ? parseFloat(valB.replace(/[^\d.-]/g, ''))
            : Number(valB)

        result = numA - numB
      }

      return this.currentSortDirection === 'asc' ? result : -result
    })
  }
}
