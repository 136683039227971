import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import moment from 'moment'
import {$} from '@/facade'
import {NftCollection} from '../resource/NftCollection'
import {AxiosResponse} from 'axios'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

@HttpExclude()
export class NftCollectionCollection extends ExpansibleCollection<
  NftCollection
> {
  resource?: INftCollectionCollectionResourceHolder

  @RequestExpose() showBoughtInOneMonth: boolean | null = null
  @RequestExpose() networkIdentifier: string | null = null
  @RequestExpose() slugDapp: string | null = null
  @RequestExpose() idBlockchainVersionFk: number | null = null
  @RequestExpose() isVisible: boolean | null = null
  @RequestExpose() minOrder: number | null = null

  constructor() {
    super(NftCollection)
  }

  set blockchainVersion(input: BlockchainVersion | null) {
    this.idBlockchainVersionFk = input?.$id || null
  }

  get lastTimeUpdated() {
    const date = moment.utc(this.items[0]?.updatedAt)

    return date.format($.translate('dateFormat.datetimeAlmostFull') as string)
  }

  async queryAsPage() {
    return this.listNftCollection()
  }

  async listNftCollection(): Promise<AxiosResponse<this>> {
    return await Request.get(`/client/nft-collection`, {
      params: this.params,
    })
      .name('loadNftCollection')
      .as(this)
      .getResponse()
  }
}

export interface INftCollectionCollectionResourceHolder {}
