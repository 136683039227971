






















import {Vue, Component, Prop} from 'vue-property-decorator'
import {NftCollection} from '@/model/resource/NftCollection'
import notAvailableImage from '@/assets/img/not-available.png'

@Component({})
export default class NftCollectionImageRender extends Vue {
  @Prop({type: NftCollection, required: true}) nftCollection!: NftCollection

  get nftCollectionImageUrl() {
    if (!this.nftCollection.imageUrl) {
      return notAvailableImage
    }

    return this.nftCollection.imageUrl
  }

  get ghostMarketCollectionUrl() {
    return `${process.env.VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL}${this.nftCollection.slug}`
  }

  onImageError(event: Event) {
    const target = event.target as HTMLImageElement
    target.src = notAvailableImage
  }
}
